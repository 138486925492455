import { ColumnBuilder, ColumnItem } from '../muiColumnBuilder';

export default () => new ColumnBuilder([
  new ColumnItem('eventID', 'Event ID'),
  new ColumnItem('eventName', 'Event name'),
  new ColumnItem('isMember', 'is Member').setComponent('boolean'),
  new ColumnItem('isTransactionSuccessfull', 'Is TXN Successful').setComponent('boolean'),
  new ColumnItem('transactionAmount', 'Transaction Amount ($)'),
  new ColumnItem('transactionID', 'Transaction ID'),
  new ColumnItem('transactionTimeStamp', 'Transaction Timestamp'),
])
  .showPrint(false);
