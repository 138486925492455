/* eslint-disable no-alert */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable newline-per-chained-call */
import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { H2, Button } from '@allthingswww/client-act-shared';
import { useSelector, useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';

import Layout from '../components/Layout';
import { getPurchaseByEventID } from '../redux/purchase';
import purchasesTableBuilder from '../components/MemberArea/MemberPages/Builders/purchasesTableBuilder';

const ActionButtons = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-around;
`;

// eslint-disable-next-line camelcase
const showQrCode = async (payment_method_id) => {
  const paymentMethod = await fetch('/.netlify/functions/get-payment-method', {
    method: 'post',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      payment_method: payment_method_id,
    }),
  })
    .then((response) => response.json())
    .then((response) => response);
  const url = btoa(JSON.stringify(paymentMethod));
  if (typeof window !== 'undefined') return navigate(`/qrcode/${url}`);
};

// eslint-disable-next-line camelcase
const showBillingDetails = async (payment_method_id) => {
  const paymentMethod = await fetch('/.netlify/functions/get-payment-method', {
    method: 'post',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      payment_method: payment_method_id,
    }),
  })
    .then((response) => response.json())
    .then((response) => response);
  // eslint-disable-next-line camelcase
  const { billing_details } = paymentMethod;
  if (typeof window !== 'undefined') return navigate('/billingDetails', { state: billing_details });
};
const showCheckoutSummary = (data) => {
  if (typeof window !== 'undefined') return navigate('/purchasedEventItems', { state: { allItemsToBePaid: data?.allItemsToBePaid } });
};

const showAttendees = (data) => {
  if (typeof window !== 'undefined') return navigate('/showAttendees', { state: { amountToBePaidDetails: (data?.amountToBePaidDetails) || [] } });
};

export default (props) => {
  const id = props['*'];
  const purchases = useSelector(getPurchaseByEventID(id));
  const data = purchases.map(({
    event, member, transaction, additionalTextField,
  }) => {
    // const { transactionData } = transaction;
    // const { paymentIntent } = transactionData;
    // // eslint-disable-next-line camelcase
    // const { payment_method } = paymentIntent;
    // const billingInformation = getPaymentMethod(payment_method);
    // console.log('billingInformation', billingInformation);

    const formattedData = {
      eventID: event._id,
      eventName: event.name,
      isMember: typeof (member) !== 'undefined',
      transactionAmount: transaction.amount / 100,
      transactionTimeStamp: transaction.dateTime,
      isTransactionSuccessfull: transaction.success,
      transactionID: transaction._id,
      transactionData: transaction.transactionData,
      data: null,
    };
    if (additionalTextField) {
      formattedData.data = JSON.parse(additionalTextField);
    }
    return formattedData;
  });

  const muiDataTableBuilder = purchasesTableBuilder()
    .setData(data)
    .getColumnItemByKey('eventID')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('eventName')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('isMember')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('isTransactionSuccessfull')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('transactionAmount')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('transactionID')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('transactionTimeStamp')
    .show()
    .moveToEnd()
    .build()

    .sortVisible()
    .showDownload(true)
    .applyOptions({
      filterType: 'checkbox',
      selectableRows: 'multiple',
      customToolbarSelect: () => {},
    });

  const customToolBarRenderer = (selectedRows) => {
    const selectedItems = muiDataTableBuilder.findItemsBySelectionData(
      selectedRows,
    );
    // eslint-disable-next-line camelcase
    const { payment_method } = selectedItems[0].transactionData.paymentIntent;
    const { data: allData } = selectedItems[0];

    return (
      <ActionButtons>
        {selectedItems.length === 1 && (
          <>
            <Button.Primary href="" onClick={() => showQrCode(payment_method)}>
              View QR Code
            </Button.Primary>
            <Button.Primary href="" onClick={() => showBillingDetails(payment_method)}>
              Show Billing Details
            </Button.Primary>
            {data && (
            <Button.Primary href="" onClick={() => showCheckoutSummary(allData)}>
              Show checkout summary
            </Button.Primary>
            )}
            <Button.Primary href="" onClick={() => showAttendees(allData)}>
              View Attendees
            </Button.Primary>
          </>
        )}
      </ActionButtons>
    );
  };

  muiDataTableBuilder.applyOptions({
    customToolbarSelect: customToolBarRenderer,
  });

  return (
    <Layout title="Event purchase details" subtitle="">
      <H2>Purchase details</H2>
      <MUIDataTable
        title="Event RSVPs"
        data={data}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...muiDataTableBuilder.build()}
      />
    </Layout>
  );
};
